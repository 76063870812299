import React from "react";
import styles from "./Style.module.css";
import Draggable from "react-draggable";
import { ReactComponent as CancelIcon } from "assets/vectors/ads/cross.svg";
import { is_empty } from "utils/validations";

export const Dialogue = (props) => {
    const scale = props?.scale;
    const height = props?.height;
    const hide = props?.hide;
    const position = { x: 0, y: -height, ...props?.position };
    const draggable = props?.draggable;
    const onClose = props?.onClose;
    const onDragEnd = props?.onDragEnd;
    const text = props?.text;
    const color = props?.color;
    const rescale = props?.rescale;
    const style = props?.style;
    const text_style = props?.text_style;

    if (hide) return null;
    return (
        <Draggable
            scale={scale}
            bounds="parent"
            position={position}
            axis={draggable ? "both" : "none"}
            onStop={onDragEnd}
        >
            <div style={style} className={`${styles.root} ${draggable && styles.draggable}`}>
                <svg
                    width={234 * (rescale ? scale : 1)}
                    height={156 * (rescale ? scale : 1)}
                    className={`${styles.container} ${
                        draggable && styles.draggable
                    }`}
                    viewBox="0 0 78 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M76 0H2C0.895431 0 0 0.895431 0 2V41C0 42.1046 0.89543 43 2 43H30.5695C31.1595 43 31.7193 43.2605 32.0993 43.7117L37.9702 50.6833C38.7693 51.6323 40.2307 51.6323 41.0298 50.6833L46.9007 43.7117C47.2807 43.2605 47.8405 43 48.4305 43H76C77.1046 43 78 42.1046 78 41V2C78 0.895431 77.1046 0 76 0Z"
                        fill={color}
                    />
                </svg>

                {text ? (
                    <div
                        style={!is_empty(text_style) ? text_style: {
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontWeight: 600,
                            fontSize: "28px",
                            width: "222px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </div>
                ) : null}

                {draggable ? (
                    <CancelIcon id={`CancelIcon`} style={{width: `${4* (rescale ? scale : 1)}rem`, height: `${4* (rescale ? scale : 1)}rem`, top: `${2* (rescale ? scale : 1)}rem`, right: `${2* (rescale ? scale : 1)}rem`}} className={styles.close} onClick={onClose} />
                ) : null}
            </div>
        </Draggable>
    );
};
