import React from "react";
import styles from "./Style.module.css";
import Draggable from "react-draggable";
import { ReactComponent as CancelIcon } from "assets/vectors/ads/cross.svg";
import { is_empty } from "utils/validations";

export const RightRibbon = (props) => {
    const scale = props?.scale;
    const height = props?.height;
    const hide = props?.hide;
    const position = { x: 0, y: -height, ...props?.position };
    const draggable = props?.draggable;
    const onClose = props?.onClose;
    const onDragEnd = props?.onDragEnd;
    const text = props?.text;
    const color = props?.color;
    const rescale = props?.rescale;
    const style = props?.style;
    const text_style = props?.text_style;

    if (hide) return null;
    return (
        <Draggable
            scale={scale}
            bounds="parent"
            position={position}
            axis={draggable ? "both" : "none"}
            onStop={onDragEnd}
        >
            <div style={style} className={`${styles.root} ${draggable && styles.draggable}`}>
                <svg
                    width={309 * (rescale ? scale : 1)}
                    height={63 * (rescale ? scale : 1)}
                    className={`${styles.container} ${
                        draggable && styles.draggable
                    }`}
                    viewBox="0 0 103 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M103 21V0H0L6.5 10.5L0 21H103Z" fill={color} />
                </svg>

                {text ? (
                    <div
                        style={!is_empty(text_style) ? text_style: {
                            position: "absolute",
                            top: "9px",
                            left: "22px",
                            fontWeight: 600,
                            fontSize: "30px",
                            width: "278px",
                            height: "45px",
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </div>
                ) : null}

                {draggable ? (
                    <CancelIcon id={`CancelIcon`} style={{width: `${4* (rescale ? scale : 1)}rem`, height: `${4* (rescale ? scale : 1)}rem`, top: `${2* (rescale ? scale : 1)}rem`, right: `${2* (rescale ? scale : 1)}rem`}} className={styles.close} onClick={onClose} />
                ) : null}
            </div>
        </Draggable>
    );
};
