import React from "react";
import styles from "./Style.module.css";
import Draggable from "react-draggable";
import { ReactComponent as CancelIcon } from "assets/vectors/ads/cross.svg";
import { is_empty } from "utils/validations";

export const TopRight = (props) => {
    const scale = props?.scale;
    const height = props?.height;
    const hide = props?.hide;
    const position = { x: 0, y: -height, ...props?.position };
    const draggable = props?.draggable;
    const onClose = props?.onClose;
    const onDragEnd = props?.onDragEnd;
    const text = props?.text;
    const color = props?.color;
    const rescale = props?.rescale;
    const style = props?.style;
    const text_style = props?.text_style;

    console.log("props", props);

    if (hide) return null;
    return (
        <Draggable
            scale={scale}
            bounds="parent"
            position={position}
            axis={draggable ? "both" : "none"}
            onStop={onDragEnd}
        >
            <div style={style} className={`${styles.root} ${draggable && styles.draggable}`}>
                <svg
                    width={200 * (rescale ? scale : 1)}
                    height={200 * (rescale ? scale : 1)}
                    className={`${styles.container} ${
                        draggable && styles.draggable
                    }`}
                    viewBox="0 0 84 84"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.82843 0H82C83.1046 0 84 0.895431 84 2V81.1716C84 82.9534 81.8457 83.8457 80.5858 82.5858L1.41422 3.41421C0.154289 2.15428 1.04662 0 2.82843 0Z"
                        fill={color}
                    />
                </svg>
                {text ? (
                    <div
                        style={!is_empty(text_style) ? text_style: {
                            position: "absolute",
                            top: "3px",
                            right: "6px",
                            textAlign: "right",
                            fontWeight: 500,
                            fontSize: "41px",
                            width: "100px",
                            height: "100px",
                        }}
                    >
                        {text}
                    </div>
                ) : null}

                {draggable ? (
                    <CancelIcon id={`CancelIcon`} style={{width: `${4* (rescale ? scale : 1)}rem`, height: `${4* (rescale ? scale : 1)}rem`, top: `${2* (rescale ? scale : 1)}rem`, right: `${2* (rescale ? scale : 1)}rem`}} className={styles.close} onClick={onClose} />
                ) : null}
            </div>
        </Draggable>
    );
};
